<template>
  <HeroImageAndServicesSection
    v-if="hasShop"
    :block="block"
    :globals="globals"
  />
  <HeroImage v-else :block="block" :globals="globals" />
</template>

<script setup lang="ts">
const { hasShop } = useBaseCountryConfig();

defineProps({
  block: {
    type: Object,
    default: () => {},
  },
  globals: {
    type: Object,
    default: () => {},
  },
});
</script>

<!-- eslint-disable vue/valid-v-for -->
<!-- eslint-disable vue/no-v-html -->
<!-- eslint-disable vue/no-v-for-template-key -->
<template>
  <section class="relative overflow-hidden bg-black md:min-h-[550px]">
    <picture class="size-full">
      <template v-for="image in block.responsiveHeroImage" :key="image.id">
        <source
          v-if="image?.typeHandle === 'BlockMobileImage' && image?.image"
          media="(max-width: 768px)"
          :srcset="image?.image?.[0]?.url"
          :type="image?.image?.[0]?.mimeType"
        />
        <source
          v-if="image?.typeHandle === 'BlockTabletImage' && image?.image"
          media="(max-width: 992px)"
          :srcset="image?.image?.[0]?.url"
          :type="image?.image?.[0]?.mimeType"
        />
        <source
          v-if="image?.typeHandle === 'BlockDesktopImage' && image?.image"
          media="(min-width: 993px)"
          :srcset="image?.image?.[0]?.url"
          :type="image?.image?.[0]?.mimeType"
        />
        <source
          v-if="image?.typeHandle === 'BlockDesktopImage' && image?.image"
          media="(min-width: 993px)"
          :srcset="image?.fallback?.[0]?.url"
          :type="image?.fallback?.[0]?.mimeType"
        />
        <NuxtImg
          v-if="image?.typeHandle === 'BlockDesktopImage' && image?.image"
          class="inset-0 z-[1] h-[220px] w-full object-cover object-center md:absolute md:h-full"
          :src="image?.image?.[0]?.url"
          :alt="heroImageAlt"
        />
      </template>
    </picture>

    <div
      class="inset-0 z-[1] size-full md:absolute"
      style="
        background: linear-gradient(
          90deg,
          rgba(0, 0, 0, 0.5) 4%,
          rgba(0, 0, 0, 0) 100%
        );
      "
    ></div>
    <div
      class="m-auto max-w-6xl bg-background-light pb-5 pt-3 md:bg-transparent md:py-10"
    >
      <div
        class="relative z-[19] grid gap-6 px-3 md:max-w-[50%] md:px-4 lg:px-3"
      >
        <h1
          class="text-4xl leading-tight text-supporting-black-87 md:text-5xl md:text-white lg:text-6xl"
        >
          {{ block.heading }}
        </h1>
        <div
          class="checkmarks-light [&>*]text-white hidden text-lg text-white antialiased md:block md:text-lg [&_li]:pb-2"
          v-html="block.contentDesktop"
        ></div>
        <div
          class="checkmarks-dark text-dynamic-primary md:hidden [&>*]:text-lg"
          v-html="block.contentMobile"
        ></div>
        <div class="grid gap-6 md:grid-cols-[1fr_1fr] md:items-center">
          <template v-for="childBlock in block.children" :key="childBlock.id">
            <template v-if="childBlock.typeHandle === 'BlockBookButtonLarge'">
              <EditorialButtonLink
                class="flex md:inline-flex"
                :url="childBlock.redirectUrl"
                :inline-svg="childBlock.icon.inlineSvg"
              >
                {{ childBlock?.text }}
              </EditorialButtonLink>
            </template>
          </template>
          <ClientOnly
            ><NextAvailableTime
              v-if="block?.showNextAvailTime"
              :time-slot-delay="globals?.timeSlotDelay?.number || 10"
            ></NextAvailableTime
          ></ClientOnly>
        </div>
        <div
          class="grid grid-cols-[max-content_max-content_max-content] justify-center gap-[5%] text-supporting-black-87 md:justify-start md:gap-4 md:text-white"
        >
          <template v-for="childBlock in block.children" :key="childBlock.id">
            <template
              v-if="childBlock.typeHandle === 'BlockSocialProofStatistics'"
            >
              <template
                v-for="socialproofscore in childBlock.socialproofscore"
                :key="socialproofscore.id"
              >
                <div class="grid justify-items-center">
                  <span
                    class="line-clamp-6 font-title text-4xl leading-snug md:text-5xl"
                  >
                    {{ socialproofscore?.score }}
                  </span>
                  <span class="text-base">
                    <span class="hidden md:inline">
                      {{ socialproofscore?.unitDesktop }}
                    </span>
                    <span class="inline md:hidden">
                      {{ socialproofscore?.unitMobile }}
                    </span>
                  </span>
                </div>
              </template>
            </template>
          </template>
        </div>
        <div
          class="grid grid-cols-[max-content_max-content] items-center justify-center md:hidden md:justify-start md:gap-4"
        >
          <BlockAppButtons :globals="props?.globals" />
        </div>
      </div>
      <svg
        class="pointer-events-none absolute top-[calc(100%-197px)] z-10 md:hidden"
        viewBox="0 0 394 261"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 35.2589C0 35.2589 73 11.9151 199 3.84101C325 -4.23312 394 2.92975 394 2.92975L393 261H0V35.2589Z"
          fill="#FDFAF5"
          fill-opacity="0.8"
        ></path>
      </svg>
    </div>
  </section>
</template>

<script setup lang="ts">
const props = defineProps({
  block: {
    type: Object,
    default: () => {},
  },
  globals: {
    type: Object,
    default: () => {},
  },
});

const heroImageAlt = computed(
  () =>
    props?.block?.responsiveHeroImage
      ?.filter((item) => item.typeHandle === 'BlockImageAlt')
      ?.map((item) => item.text)
      ?.pop() || ''
);
</script>

<style>
.checkmarks-light ul li {
  list-style: none;
  padding-left: 35px;
  position: relative;
}

.checkmarks-light ul li:before {
  content: '';
  display: inline-block;
  position: absolute;
  left: 1px;
  top: 2px;
  height: 24px;
  width: 24px;
  background-color: transparent;
  background-image: url(~/assets/pictograms/static/check-mark-light.svg);
}

.checkmarks-dark ul li {
  list-style: none;
  padding-left: 35px;
  position: relative;
}

.checkmarks-dark ul li:before {
  content: '';
  display: inline-block;
  position: absolute;
  left: 1px;
  top: 2px;
  height: 24px;
  width: 24px;
  background-color: transparent;
  background-image: url(~/assets/pictograms/static/check-mark-dark.svg);
}
</style>
